<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>𝗠𝗼𝗰𝗸𝘂𝗺𝗲𝗻𝘁</title>

<link rel="icon" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAnFJREFUOE9jZEAHIiK8XHbq878LcPn+Z2BgA0kzMjD84vzwbfO3Q0cTGd4wfEbWwojM4Qq0Wv1NkDuY4+vXk0yfvtxi+Pf/L1ieiZH5Hz+P2g8ubnOu99/Xflt/JBSmD24Ae4LLw3///zKyvnizB8NVSAK/JURcmBhZ/v9csFse6joGBk5/y9V/BDjMCWmGmQMyhOXDj5PfNx4PZWQQEeFl8DP8yPX0xQJcNgc5earduHfn3bUHt9/A1HyTlkhg2HSenxHk739sDDJMHz5fB0lmBMfobzm858GTVy8+whS/2HAijouNg4nfw2DBfyaI6D8BXk2m34xPGRmTXH9yPn+5AhZgeyYs8bPU0BPWTfTecO/54/cC/PycT1cejgBp2nb60Iuw2pzt/6EB+11SPIKRIcn1P9fTF/Nhtr3edApkGzOIb5Tiv6klo8TMy8RWAiZvnhG69cqDm69A/G/SEokYBnzdcSkRXyz8/fePgd/DYD7IK2ADmJLdfnI8ewH2AhsbK8v7TWdjsRnQvnTmtcrodC2Q3PYzR16G1mTv/C4jEcHIFWS95h/rf2lQIOqqaoqdmLzSG6TIIi1067r2aS5SwqLsNXMmXJqwat5ZHUV14TsP7r7/8//fv19C3JqMvxmeMjKIMPAy+LmCo7EmMdcsPyBW49Cl0y8jqnN3/mH4xxDi4q2xYff2G7+Z/6E4DBKNu/nBKZEnyGb1T15Wc7Znb/aAYgmkFBZd2LyDmpCgKkhLysz/fy7Yg0jKMFtASfq7MA/2zMTHo/aDm9uc8/2Xtd/XH8fMTHCnijDwctlZY2bnd183fztyK5HhzRuU7AwAkSMXjP0Y8hoAAAAASUVORK5CYII=">
<link rel="icon" sizes="any" href="/mockument-128.png">

<style>/* core styles */</style>

<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Sans Math:100,400,800">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Sans:100,200,300,400,500,600,700,800,900">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Emoji">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Sans Symbols">
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Noto Sans Symbols 2">

<link rel="preload" href="https://cdn.jsdelivr.net/npm/typescript" as="script" />
<link rel="preload" href="https://unpkg.com/ts-jsonps" as="script" />
<link rel="preload" href="https://cdn.jsdelivr.net/npm/ag-grid-community@32.2.2/dist/ag-grid-community.min.js" as="script" />
<link rel="preload" href="https://unpkg.com/echarts@5.5.1/dist/echarts.js" as="script" />

<script>/* core scripts */</script>

</head>
<body>

<!-- init html -->

</body>
</html>