
<div id=main
  style="
    position: absolute; left: 0; top: 0; width: 100%; height: 100%;">
  <div id=contentHost style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; overflow: hidden;">
    <textarea id="format_textarea" autofocus></textarea>
  </div>
  <div id="toolbar">
    <div id="logo">
      <span class="accessible-caption">Mockument</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 11">
        <path id="M" d="M0,10 L4,0 L8,6 L12,0 L16,10 L12,10 L11,8 L8,11 L5,8 L4,10 Z" fill="currentColor" />
      </svg>
    </div>
    <div id="format-tools">
      <button id="unicodeFormatToggle"><b>&#120192;</b><span class="accessible-caption">nicode formatting</span></button>
      <span id="unicode-subsection">
        <button id="uni-boldItalicToggle"><b>B</b><span class="accessible-caption">old/italic</span></button>
        <button id="uni-underlineToggle"><b>U</b><span class="accessible-caption">underline</span></button>
        <button id="uni-joyToggle"><b>J</b><span class="accessible-caption">oy</span></button>
        <button id="uni-wtfToggle"><b>W</b><span class="accessible-caption">ide/typewriter/fractur</span></button>
        <button id="uni-cursiveSuperToggle"><b>C</b><span class="accessible-caption">ursive/superscript</span></button>
        <button id="uni-rpxToggle"><b>R</b><span class="accessible-caption">ound/plate/box</span></button>
        <button id="uni-khazadToggle"><b>K</b><span class="accessible-caption">hazad-Dûm</span></button>
      </span>

      <button id="boldItalicToggle"><b>B</b><span class="accessible-caption">old/italic</span></button>
      <button id="headingToggle"><b>H</b><span class="accessible-caption">eading 1, 2, 3...</span></button>
      <button id="dividerInsert"><b>D</b><span class="accessible-caption">ivider</span></button>
      <button id="codeInsert"><b>R</b><span class="accessible-caption">unnable code block</span></button>

    </div>

    <div id="version">
      v0.16.45<sup>?</sup>
    </div>
  </div>
  </div>